import React from "react";
import './AboutUs.css'

const AboutUs = () => {
    return (
        <div className="aboutUsContainer">
            <h1>About Us</h1>
            {/* <h2></h2> */}
            <p>
            Welcome to El Molcajete, your passport to authentic Mexican cuisine right here in Cloverdale and Ukiah! Our restaurants are dedicated to bringing the rich and diverse flavors of Mexico to your table, providing a truly immersive dining experience that will transport you to the heart of Mexico.
            </p>
        </div>
    )
}

export default AboutUs;