import React, { useState } from 'react';  // Import useState here
import { FaFacebook, FaInstagram } from "react-icons/fa";
import AccesibilityModal from '../AccessibilityModal/AccessibilityModal';
import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcons,
  SocialIconLink,
  FooterHours,
} from "./FooterElements";

const Footer = () => {
  // State to control the visibility of the modal
  const [isModalOpen, setModalOpen] = useState(false);

  // Function to open the modal
  const openModal = () => setModalOpen(true);

  // Function to close the modal
  const closeModal = () => setModalOpen(false);

  return (
    <FooterContainer>
        <FooterWrap>
            <SocialMedia>
              <SocialMediaWrap>
                <SocialLogo to=''>El Molcajete Cloverdale</SocialLogo>
                <FooterHours>
                  Monday - Friday 11am-9pm
                </FooterHours>
                <FooterHours>
                  Saturday - Sunday 10am-9pm
                </FooterHours>
                <SocialIcons>
                    <SocialIconLink href='#' target='_blank' aria-label="Facebook" rel='noopener noreferrer'>
                        <FaFacebook /> 
                    </SocialIconLink>
                    <SocialIconLink href='#' target='_blank' aria-label="Instagram" rel='noopener noreferrer' >
                        <FaInstagram /> 
                    </SocialIconLink>
                    {/* Add a button or link to open the modal */}
                    <button className='ally-button' onClick={openModal} style={{ marginLeft: '20px', cursor: 'pointer' }}>Accessibility Statement</button>
                </SocialIcons>
              </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>
        {/* Modal Component */}
        <AccesibilityModal isOpen={isModalOpen} onClose={closeModal} />
    </FooterContainer>
  )
}

export default Footer;
