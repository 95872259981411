import React from "react";
import { Nav, NavIcon, NavLink } from "./NavbarElements";
import logo from '../../images/logo-molcajete-000.png'

const Navbar = ({ toggle }) => {
    return (
        <>
            <Nav>
                <NavLink to='/'><img src={logo} alt="Logo" style={{ height: '75px', padding: '10px'}}/></NavLink>
                <NavIcon onClick={ toggle }>
                    <p>Menu</p>
                </NavIcon>
            </Nav>
        </>
    )
}

export default Navbar