
const menuItems = {
    Appetizers: [
        { name: 'Bocadillo Platter', price: '20.50', description: 'Nachos, quesadilla, taquitos rancheros, flautas, sour cream, guacamole, nachos fries, and mexican salsa'},
        { name: 'Quesadilla de harina', price: '7.99', description: ''},
        { name: 'Quesadilla con carne', price: '10.50', description: ''},
        { name: 'Quesadilla suiza 10"', price: '11.99', description: 'Flour tortilla with cheese, guacamole, sour cream and choice of meat'},
        { name: 'Super quesadilla 14"', price: '13.75', description: 'Guacamole, sour cream, pico de gallo, & choice of meat'},
        { name: 'Tostada de ceviche', price: '9.75', description: 'with your choice of fish or shrimp'},
        { name: 'Flautas de maiz', price: '11.99', description: 'corn tortilla, lettuce, guacamole, sour cream and pico de gallo'},
        { name: 'Taquitos rancheros de harina', price: '12.50', description: 'Flour tortilla, guacamole, pico de gallo, sour cream, lettuce, and your choice of meat'},
        { name: 'Dip Guacamole', price: '9.99', description: ''},
        { name: 'Choriqueso', price: '13.50', description: 'chorizo, cheese, jalapeños, pico de gallo and tortillas'},
        { name: 'Super nachos no carne', price: '10.99', description: ''},
        { name: 'Nachos fries', price: '11.75', description: ''},
        { name: 'Steak fries', price: '13.25', description: ''},
        { name: 'Super nachos', price: '14.50', description: 'melted cheese, refried beans, pico de gallo, sour cream, guacamole & your choice of meat'},
    ],
    Traditional_Combos: [
        { name: 'Tour de Mexico', price: '27.50', description: '1 taco, 1 tamal, 1 chile relleno, 1 enchilada served with rice, beans and lettuce'},
        { name: 'Fajitas', price: '', description: 'Your choice of marinated and grilled beef, chicken, prawns, or vegetables, presented sizzling hot to your table over a bed of sautéed onion, bell peppers. Served with rice, beans, pico de gallo, sour cream, guacamole & tortillas'},
        { name: 'Steak Fajitas', price: '22.50', description: ''},
        { name: 'Chicken Fajitas', price: '21.50', description: ''},
        { name: 'Prawns Fajitas', price: '22.99', description: ''},
        { name: 'Veggie Fajitas', price: '18.25', description: ''},
        { name: 'Duo Fajitas', price: '22.50', description: 'chicken and beef'},
        { name: 'Combos', price: '', description: 'All combos are served with rice and beans'},
        { name: '#1 Enchilada', price: '12.99', description: ''},
        { name: '#2 Taco', price: '12.99', description: ''},
        { name: '#3 Chile Relleno', price: '14.99', description: ''},
        { name: '#4 Tostada', price: '12.99', description: ''},
        { name: '#5 Tamal', price: '12.99', description: ''},
        { name: '#6 Two enchiladas', price: '15.99', description: ''},
        { name: '#7 Enchilada and chile relleno', price: '16.25', description: ''},
        { name: '#8 Enchilada and taco', price: '16.75', description: ''},
        { name: '#9 Two tacos', price: '16.25', description: ''},
        { name: '#10 Taco & chile relleno', price: '17.50', description: ''},
        { name: '#11 Enchilada & tostada', price: '17.50', description: ''},
        { name: '#11 Chile relleno & tamal', price: '17.50', description: ''},
    ],
    Breakfast: [
        { name: 'Breakfast Burrito', price: '12.99', description: 'rice, refried beans, cheese, eggs, & choice of meat, served all day. (add fries $2)'},
        { name: 'Chilaquiles verdes o rojos', price: '14.99', description: ''},
        { name: 'Egg dishes', price: '13.50', description: 'Rancheros, a la Mexicana, or con Chorizo'},
    ],
    Soups: [
        { name: 'Pozole', price: '13.99', description: ''},
        { name: 'Chicken Tortilla Soup', price: '12.99', description: ''},
        { name: 'Caldo de Albondigas', price: '14.25', description: 'meatball soup'},
        { name: 'Caldo de Pollo', price: '13.95', description: 'chicken soup'},
        { name: '7 Mares', price: '19.50', description: ''},
        { name: 'Caldo de Camaron', price: '13.25', description: '' },
        { name: 'Menudo', price: '12.75', description: ''},
    ],
    Salads: [
        { name: 'House Salad', price: '9.50', description: 'lettuce, onions, bell peppers, tomatoes, and avocado'},
        { name: 'Chicken Salad', price: '14.25', description: 'chicken, lettuce, onions, bell peppers, tomatoes, and avocado'},
        { name: 'Taco Salad', price: '13.50', description: 'choice of meat, lettuce, guacamole, sour cream, pico de gallo, whole beans, and rice'},
    ],
    Kids_Menu: [
        { name: 'Kids Menu', price: '', description: 'Kids Only 8 & Under | Served include rice, beans, and fries.'},
        { name: 'Taco', price: '8.50', description: ''},
        { name: 'Quesadilla', price: '8.50', description: ''},
        { name: 'Enchilada', price: '8.50', description: ''},
        { name: 'Burrito', price: '8.50', description: ''},
    ],
    Tortas_y_Tacos: [
        { name: 'Torta Regular', price: '9.99', description: ''},
        { name: 'Torta de milanesa', price: '11.50', description: ''},
        { name: 'Crispy tacos', price: '4.75', description: ''},
        { name: 'Soft tacos', price: '4.15', description: ''},
        { name: 'Taco de tripa', price: '4.25', description: ''},
        { name: 'Taco de lengua', price: '4.25', description: ''},
        { name: 'Taco de pescado', price: '4.25', description: 'fish'},
        { name: 'Taco de pescado empanizado', price: '4.25', description: 'breaded fish'},
        { name: 'Taco de camaron', price: '4.25', description: 'shrimp'},
    ],
    Meat_Choices: [
        { name: 'Meat Choices', price: '', description: ''},
        { name: '', price: '', description: 'Carne Asada / Steak'},
        { name: '', price: '', description: 'Carne Molida / Ground Beef'},
        { name: '', price: '', description: 'Desebrada / Shredded Beef'},
        { name: '', price: '', description: 'Carnitas / Fire roasted pork'},
        { name: '', price: '', description: 'Pollo Asado / Grilled Chicken'},
        { name: '', price: '', description: 'Pollo Desebrado / Shredded Chicken'},
        { name: '', price: '', description: 'Pastor / Marinated Pork'},
        { name: '', price: '', description: 'Chorizo / Mexican Sausage'},
        { name: '', price: '', description: 'Lengua / Beef Tongue'},
        { name: '', price: '', description: 'Cabeza / Beef Head'},
        { name: '', price: '', description: 'Pescado / Fish'},
        { name: '', price: '', description: 'Nopales / Cactus'},
        { name: '', price: '', description: 'Vegetariano / Vegetarian'},
    ],
    Tostadas_y_Cocteles: [
        { name: 'Ceviche Tostada', price: '9.50', description: ''},
        { name: 'Camaron Tostada', price: '9.50', description: ''},
        { name: 'Camaron & Pulpo Tostada', price: '10.50', description: ''},
        { name: 'Coctel de Camaron', price: '', description: 'Small 16.75 | Large 18.99'},
        { name: 'Coctel de Pulpo', price: '', description: 'Small 17.99 | Large 19.99'},
        { name: 'Campechana', price: '', description: 'Small 17.99 | Large 19.25'},
        { name: 'Mojarra a la diabla / chipotle', price: '23.50', description: 'topped with sauce, served with rice, lettuce, avocado, tomato, and onions'},
        { name: 'Mojarra frita', price: '22.50', description: 'served with rice, lettuce, avocado, tomato, and onions'},
        { name: 'Aguachile', price: '27.50', description: 'served with avocado, mango, cucumber, onions, tomatoes and spicy sauce'},
    ],
    Camarones: [
        { name: 'Camarones a la diabla', price: '23.50', description: ''},
        { name: 'Camarones al mojo de ajo', price: '23.50', description: ''},
        { name: 'Camarones empanizados', price: '23.75', description: ''},
        { name: 'Camarones rancheros', price: '24.25', description: ''},
        { name: 'Camarones colima', price: '24.99', description: ''},
        { name: 'Camarones a la crema', price: '24.99', description: ''},
        { name: 'Pescado empanizado', price: '25.25', description: ''},
        { name: 'Filete a la plancha', price: '24.99', description: ''},
        { name: 'Quesadilla de camaron', price: '16.25', description: ''},
        { name: 'Super quesadilla de camaron', price: '19.50', description: ''},
        
    ],
    Side_Orders: [
        { name: 'Enchilada', price: '5.99', description: ''},
        { name: 'Tostada', price: '6.99', description: ''},
        { name: 'Chile Relleno', price: '6.99', description: ''},
        { name: 'Tamal', price: '5.75', description: ''},
        { name: 'Rice and Beans', price: '6.99', description: ''},
        { name: 'Corn Tortillas', price: '3.25', description: ''},
        { name: 'Flour Tortillas', price: '3.50', description: ''},
        { name: 'Guacamole', price: '5.99', description: ''},
        { name: 'Sope', price: '6.99', description: ''},
    ],
    House_Specials: [
        { name: 'Platillos', price: '', description: 'All combos are served with rice and beans, sour cream, guacamole, pico de gallo, and tortillas (corn or flour)'},
        { name: 'Molcajete', price: '28.99', description: 'steak, prawns, chicken, rice, beans, cactus and sauce'},
        { name: 'Pollo en chipotle', price: '21.99', description: 'served with rice, beans, bell peppers, and onions'},
        { name: 'Trio Fajitas', price: '26.50', description: 'tender strips of beef, chicken breast, and prawns sizzle together to create this great fajita combination'},
        { name: 'Gringa', price: '20.99', description: 'marinated pork grilled with fresh pineapple and onions topped with melted cheese, served in a fresh 1/2 pineapple on a bed of rice'},
        { name: 'Carnitas platter', price: '20.50', description: ''},
        { name: 'Pollo con mole', price: '21.50', description: ''},
        { name: 'Pollo con crema', price: '21.99', description: ''},
        { name: 'Carne asada', price: '21.99', description: ''},
        { name: 'Steak encebollado', price: '22.50', description: ''},
        { name: 'Pollo a la plancha', price: '20.50', description: ''},
        { name: 'Steak ranchero', price: '22.50', description: ''},
        { name: 'Mar y tierra', price: '26.50', description: ''},
        { name: '2 Sopes', price: '19.50', description: ''},
        { name: 'Huarache', price: '12.50', description: ''},
        { name: 'Mole enchiladas', price: '22.50', description: ''},
        { name: 'Enchiladas suizas', price: '22.50', description: ''},
        { name: 'Chile verde (pork)', price: '20.50', description: ''},
    ],
    Burritos: [
        { name: 'Regular Burrito', price: '12.99', description: 'rice, beans, sauce & your choice of meat'},
        { name: 'Super Burrito', price: '15.99', description: 'rice, beans, sour cream, guacamole, cheese, sauce, & your choice of meat'},
        { name: 'Macho Burrito', price: '17.95', description: 'rice, refried beans or whole beans, sour cream, guacamole, cheese, sauce & your choice of meat, onion, cilantro, & tomatoes'},
        { name: 'Chimichanga', price: '15.95', description: 'rice, beans, guacamole, sour cream, tomatoes, parmesan cheese, meat, or vegetarian'},
        { name: 'Macho chimichanga', price: '17.95', description: ''},
        { name: 'Mas macho burrito', price: '26.50', description: 'rice, refried or whole beans, sour cream, guacamole, cheese, sauce, & your choice of meat, onions, cilantro, & tomatoes'},
        { name: 'Mole burrito', price: '14.50', description: 'rice, beans, sour cream, & your choice of meat'},
        { name: 'Chile relleno burrito', price: '16.75', description: ''},
        { name: 'Vegetarian burrito', price: '12.50', description: 'rice, whole beans, lettuce, tomatoes, sour cream, guacamole, & cheese'},
        { name: 'Veggie burrito', price: '13.75', description: 'steamed cauliflower, brocolli, tomatoes, carrots, zucchini, squash, whole beans, and rice'},
        { name: 'Burrito de camaron', price: '15.99', description: 'shrimp, bell peppers, onions, whole beans, & rice'},
        { name: 'Burrito super de camaron', price: '18.99', description: 'shrimp, bell peppers, onions, whole beans, rice, guacamole, sour cream & cheese'},
        { name: 'Macho burrito de camaron', price: '20.50', description: 'shrimp, bell peppers, onions, whole beans, rice, guacamole, sour cream, cheese, cilantro & tomatoes'},
        { name: 'California burrito', price: '15.99', description: 'cheese, sour cream, guacamole, pico de gallo, choice of meat & fries. (Shrimp or fish is extra)'},
    ],
    Dessert: [
        { name: 'Flan', price: '5.50', description: ''},
        { name: 'Sopapillas', price: '8.99', description: ''},
    ],
    Drinks: [
        { name: 'Soft Drinks', price: '3.99', description: ''},
        { name: 'Mexican Sodas', price: '4.25', description: ''},
        { name: 'Aguas Frescas', price: '4.50', description: 'Horchata | Jamaica | Tamarindo | Piña'},
    ],
    Beer: [
        { name: 'Michelada', price: '9.75', description: ''},
        { name: 'Cerveza importada', price: '5.99', description: ''},
        { name: 'Cerveza domesticada', price: '5.50', description: ''},
        { name: 'Cubetazo (6)', price: '29.99', description: ''},
    ],
    Margaritas: [
        { name: 'Sangria margarita', price: '12.50', description: ''},
        { name: 'Coronarita', price: '12.50', description: ''},
        { name: 'Margarita lime', price: '8.99', description: ''},
        { name: 'Margarita fresa', price: '9.99', description: ''},
        { name: 'Margarita mango', price: '9.99', description: ''},
    ],
}

export default menuItems