import { motion } from 'framer-motion';
import { useRef, useEffect, useState } from "react";
import './GallerySection.css';

import gallery1 from '../../images/gallery-1.JPG'
import gallery2 from '../../images/gallery-2.JPG'
import gallery3 from '../../images/gallery-3.JPG'
import gallery4 from '../../images/gallery-4.JPG'
import gallery5 from '../../images/gallery-5.JPG'
import gallery6 from '../../images/gallery-6.JPG'
import gallery7 from '../../images/gallery-7.JPG'

function GallerySection() {
    const [width, setWidth] = useState(0);
    const carousel = useRef();
    const [items, setItems] = useState([
        { id: 1, content: '', title:'', description: 'Margarita', price: '', imageUrl: gallery1  },
        { id: 2, content: '', title:'',description: 'Mango Margarita', imageUrl: gallery2 },
        { id: 3, content: '', title:'',description: 'Cocktel', imageUrl: gallery3 },
        { id: 4, content: '', title:'',description: 'Trio Fajitas', imageUrl: gallery4 },
        { id: 5, content: '', title:'',description: '7 Mares', imageUrl: gallery5 },
        { id: 6, content: '', title:'',description: 'Camarones al Mojo de Ajo', imageUrl: gallery6 },
        { id: 7, content: '', title:'',description: 'Carne Asada', imageUrl: gallery7 },
    ]);

    useEffect(() => {
        setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);
    }, []);

    return (
        <>
        <div className='gallery-container'>
            <h2 className='dishes'>Favorites</h2>
        <motion.div ref={carousel} className='gallery-carousel' whileTap={{cursor: "grabbing"}}>
            <motion.div 
                drag="x" 
                dragConstraints={{ right: 0, left: -width }} 
                className='gallery-inner-carousel'
            >
                {items.map(item => (
                    <motion.div 
                        className='gallery-item' 
                        key={item.id}
                        style={{ background: `url(${item.imageUrl})` }} /* Use item's imageUrl property */
                    >
                        <div className='item-content' >
                            {item.content}
                            <p className='item-title'>{item.title}</p>
                            <p className='item-description'>{item.description}</p>
                            <p className='item-price'></p>
                        </div>
                    </motion.div>
                ))}
            </motion.div>
        </motion.div>
        </div>
        </>
    );
}

export default GallerySection;
