import React from "react";
import { BrowserRouter as Router } from 'react-router-dom'
import { GlobalStyle } from "./globalStyles";
import Hero from "./components/Hero";
// import Products from "./components/Products";
// import { productData, productDataDesserts } from "./components/Products/data";
// import Feature from "./components/Feature";
import CarouselMenu from "./components/CarouselMenu"
import Footer from "./components/Footer";
// import BusinessHours from "./components/BusinessHours/BusinessHours";

// Accessibility 
import { AccessibilityProvider } from "./components/AccessibilityContext/AccessibilityContext";
import AccessibilityButton from "./components/AccessibilityButton/AccessibilityButton";
import AboutUs from "./components/AboutUs/AboutUs";
import GallerySection from "./components/GallerySection/GallerySection";

function App() {
  return (
    <AccessibilityProvider>  {/* Ensure all components inside are wrapped by AccessibilityProvider */}
    
    <Router>
    
      <GlobalStyle />
      <div style={{ filter: 'var(--grayscale-filter)', fontSize: 'var(--font-size)' }}> {/* Use CSS variables for consistent styling */}
      
      <Hero />
      <AboutUs />
      {/* <Products heading='Choose your favorite' data={productData}/> */}
      {/* <Feature /> */}
      <CarouselMenu />
      <GallerySection />
      {/* <BusinessHours /> */}
      {/* <Products heading='Dessert Time 😋' data={productDataDesserts}/> */}
      <Footer />
      </div>
    </Router>

    <AccessibilityButton />
    </AccessibilityProvider >
  );
}

export default App;