import React, { useState } from 'react'
import Navbar from '../Navbar'
import { Link } from 'react-scroll';
import {
  HeroContainer,
  HeroContent,
  HeroItems,
  HeroH1,
  HeroP,
  HeroBtn,
} from "./HeroElements";
import Sidebar from '../Sidebar';

const Hero = () => {
  const [isOpen, setIsOpen] = useState(false)
  
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
   <HeroContainer>
    <Navbar toggle={toggle}/>
    <Sidebar isOpen={isOpen} toggle={toggle}/>
    <video autoPlay muted loop playsInline className="hero-video">
        <source src="https://filesonline.s3.us-east-2.amazonaws.com/molcajete-cloverdale.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <HeroContent>
        <HeroItems>
          <HeroH1 className='hero-h1'>El Molcajete</HeroH1>
          <HeroP>Cloverdale</HeroP>
          <a href='tel:+17078944495'>
            <HeroBtn>Call</HeroBtn>
          </a>
          <a href='https://maps.app.goo.gl/BctLsrVxmCXVVAT78'>
            <HeroBtn>Directions</HeroBtn>
          </a>
            <HeroBtn>
              <Link to="menu" smooth={true} duration={500} offset={-70}>
                    Full Menu
              </Link>
            </HeroBtn>
        </HeroItems>
      </HeroContent>
   </HeroContainer>
  )
}

export default Hero